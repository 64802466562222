import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'top',
    component: () => import('@/AppSapphire'),
  },

  {
    path: '/sapphire',
    name: 'sapphire',
    component: () => import('@/AppSapphire'),
  },

  {
    path: '/scarlet',
    name: 'scarlet',
    component: () => import('@/AppScarlet'),
  },

  {
    path: '/steam',
    name: 'steam',
    component: () => import('@/AppSteam'),
  },

  {
    path: '/updates',
    name: 'updates',
    component: () => import('@/views/Updates'),
  },

  {
    path: '/updates2',
    name: 'updates2',
    component: () => import('@/views/Updates2'),
  },

  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Privacy'),
  },
]

const router = new VueRouter({
  routes,
})

export default router
